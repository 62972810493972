import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.page.html',
  styleUrls: ['./pdf-viewer.page.scss'],
})
export class PdfViewerPage {
  @Input() pdfSrc: string; // Caminho do PDF

  constructor(
    private modalController: ModalController,
  ) {
    
  }

  async close() {
    await this.modalController.dismiss();
  }
}
