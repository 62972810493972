import {Injectable} from '@angular/core';
import {AppSettings} from '../../helpers/app-settings';
import {OcorrenciasModel} from '../../models/ocorrencias.model';
import {OfflineService} from '../common/offline.service';

@Injectable({
  providedIn: 'root'
})
export class OcorrenciasService extends OfflineService {

  getTableIdentifier(): string {
    return 'ocorrencias';
  }

  async recuperarOcorrencias(forceApiLoad: boolean = false): Promise<OcorrenciasModel[]> {
    let ocorrencias: OcorrenciasModel[] = null;
    if (forceApiLoad) {
      ocorrencias = await this.getApi();
    } else {
      ocorrencias = await this.storage.get(`cache:${this.getTableIdentifier()}`);
      const isOnline = await this.isOnline();

      if (!ocorrencias && !isOnline) {
        await this.utilService.presentAlert({
          header: 'Modo offline sem dados!',
          message: 'Você precisa ter acesso a internet para baixar as ocorrências.',
          buttons: ['Ok']
        });
      } else if (!ocorrencias && isOnline) {
        ocorrencias = await this.getApi();
      }
    }

    return ocorrencias;
  }

  private async getApi() {
    let ocorrencias = null;
    await this.utilService.presentLoading({
      message: 'Listando as ocorrências .. '
    });

    try {
      const agente = await this.storage.get(`cache:agente`);

      ocorrencias = await this.getFromApi(AppSettings.OCORRENCIAS_LISTAR + agente.central, `cache:${this.getTableIdentifier()}`, true);
      await this.utilService.presentToast({
        message: 'Ocorrências atualizadas com a nuvem',
        duration: 5000,
        buttons: [
          {
            text: 'Ok',
            role: 'cancel'
          }
        ]
      });

    } catch (err) {
      if(err.status != 500){
        await this.utilService.presentAlert({
          header: 'Não foi possível recuperar as ocorrências',
          message: err.error.message,
          buttons: ['Ok']
        });
      }
      
    }

    await this.utilService.dismissLoading();
    return ocorrencias;
  }
}
