import { Injectable } from '@angular/core';
import {OfflineService} from '../common/offline.service';

@Injectable({
  providedIn: 'root'
})
export class FormularioService extends OfflineService{
  getTableIdentifier(): string {
    return 'formulario';
  }

}
